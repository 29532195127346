.n-wrapper{
    height: 10vh;
    display: flex;
    justify-content: space-between;
}
.n-left{
    flex: 1;
    align-items: center;display: flex;
    gap: 2rem;
}
.n-name{
    font-size: 1.3rem;
    font-weight: bold;
}
.n-right{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    font-weight: 400;
}
n-list{
    flex: 10;
    font-size: 15px;

}
.n-list>ul{
    list-style-type: none;
    gap: 2rem;
    display: flex;
    margin-right: 8rem;

}
.n-list>ul>*:hover{
    cursor: pointer;
    color: var(--orange);
    transform: scale(1.2);
     transition: all .2s ease-in-out;
}
.n-button{
    flex: 2;
}
@media  screen and (max-width:480px) {
  .n-list, .n-button{
    display: none;
  }

}
