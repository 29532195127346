.services{
    padding:  0 3rem 0 3rem;
    display: flex;
    height: 90vh;
    margin-bottom: 13rem;
    margin-top: 1rem ;
    margin-bottom: 8rem;
}
.awesome{
    display: flex;
    flex-direction: column;
    position: relative;
   
}
.awesome>:nth-child(1)
{
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--black);
}


.awesome>:nth-child(2) {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--orange);
}
.awesome>:nth-child(3) {
    font-size: 15px;
margin-top: 1rem;
    color: var(--gray);
}
.s-button{
    width: 8rem;
    height: 2rem;
    margin-top: 1rem;
}
.cards{
    position: relative;
}
.cards>*{
    position: absolute;
}
.s-blur2{
top:8rem;
left: 14rem;
}
@media  screen and (max-width:480px) {

.services{
    margin-top: 0;
    flex-direction: column;
    gap: 5rem;
    height: 66rem;
    padding: 0;
}
.cards{
    display: flex;
    flex-direction: column;
    gap: 17rem;
}
.cards>*{
    position: static;
}


}