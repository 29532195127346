.intro{
    display: flex;
    height: 77vh;
    margin-top: 6rem;

}
.i-left{
   position: relative;
   display: flex;
   flex: 1;
   flex-direction: column;
   gap: 2rem;
}
.i-name{
    display: flex;
    flex-direction: column;
}
.i-name>:nth-child(1){
    font-size: 3rem;
    font-weight: bold;
    color: var(--black);
}
.i-name>:nth-child(2){
    color: var(--orange);
    font-size: 3rem;
    font-weight: bold;
}
.i-name>:nth-child(3){
    font-weight: 100;
    font-size: 14px;
    color: var(--gray);
    margin-top: 10px;
}
.i-button{
    width: 6rem;
    height: 2rem;
}


.intro-icons{
    margin-top: 3rem;
    display: flex;
    gap: 0rem;
}
.intro-icons>*{
    transform: scale(0.5);
}


.i-right {
    display: flex;
    flex: 1;
    position: relative;
}
.i-right>*{
    position: absolute;
    z-index: 1;
}
.i-right>:nth-child(1){
    transform: scale(0.69);
    left: -15%;
    top: -9%;
}
.i-right>:nth-child(2) {
    transform: scale(0.68);
    left: -3rem;
    top: -4.6rem;
}
.i-right>:nth-child(3) {
    transform: scale(1.2);
    left: 10%;
    top:-1.5rem;
}
.i-right>:nth-child(4) {
    transform: scale(.5);
    top: -19%;
    border-radius: 50%;
    left: -24%;
    padding: 0;
}
.blur{
    position: absolute;
    height: 14rem;
    width: 22rem;
    border-radius: 50%;
    background: #edd0ff;
    filter: blur(72px);
    top: -18%;
    left: 56%;
    z-index: -9;
}
@media  screen and (max-width:480px) {
  .intro{
    flex-direction: column;
    height: 64rem;
    gap: 7rem;
  }
  .i-right{
    transform: scale(0.8);
    left: -3rem;
  }
  .i-right .blur{
    display: none;
  }
  .floating-div:nth-of-type(1){
    top: -7rem!important;
  }
}
