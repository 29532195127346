.t-wrapper{
    padding:  0 3rem 0 3rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    height: 100vh;
    margin-top: .2rem;
    position: relative;
    justify-content: center;
    margin-bottom: -4rem;
}
.t-heading{
    align-items: start;
    margin-bottom: -3rem;
}
.t-heading>*{
    font-size: 2rem;
    font-weight: bold;

}
.t-heading span:nth-of-type(2){
    color: var(--orange);
}
.t-blur1{
    left: 38rem;
    top: 16rem;
}
.t-blur2{
    top: 9rem;
    left: 0;
}
.testimonial{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 2rem;
    width: 30rem;
    background: rgba(255, 255, 255, 0.26);
    border: 7px solid var(--blueCard);
    box-shadow: var(--boxShadow);
    border-radius: 20px;
    height: 12rem;
}
.testimonial>img{
    width: 8rem;
    height: 8rem;
    border-radius: 100%;
}
.testimonial>span{
    color: var(--gray);
}
.t-wrapper .swiper{
    width: 100%;
    height: 70%;
}
.t-wrapper .swiper-slide{
    display: flex;
    align-items: center;
    justify-content: center;
}
.t-wrapper .swiper-pagination-bullet-active{
background: var(--orange);
}
@media  screen and (max-width:480px) {
    .t-wrapper{
     padding: 0;
    }
    .testimonial>img{
        width: 90px;
        height: 90px;
    }
}
