.c-form{
    padding: 0 3rem 0 3rem;
    display: flex;
    height: 80vh;
    margin-top: 1rem;

}
.c-right{
    display: flex;
    justify-content: center;position: relative;
    flex: 1;
}
.c-right>form{
    display: flex;flex-direction: column;
    gap: 2rem;
    align-items: center;
}
.c-right .user{
    width: 20rem;
    height: 2rem;
    padding: 0.3rem;
    outline: none;
    border: 2px solid var(--orange);
    font-size: 16px;
    border-radius:8px;
}
textarea{
    height: 5rem!important;
}
.c-blur1{
    top: 1rem;
    left: 8rem;
}
@media  screen and (max-width:480px) {
    .c-form{
        margin-top: 2rem;
       display: flex;
      flex-direction: column;
      gap: 0rem;
      padding: 0;
    }
    .c-right{
        margin-top: -2rem;
        align-items: flex-start;
        justify-content: center;
        transform: scale(.7);
        display: flex;

    }
    .w-left{
        align-items: center;
        justify-content: center;
        display: flex;
    }
}
