.portfolio {
    margin-top: -10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 70vh;
}

.portfolio span:nth-of-type(1){
    color: var(--black);
    font-weight: bold;
    font-size: 2rem;
}
.portfolio span:nth-of-type(2){
    color: var(--orange);
    font-weight: bold;
    font-size: 3rem;
}
.portfolio .swiper{
    overflow: visible!important;
}
.portfolio-slider{
    margin-top: 3rem;
    width: 100%;

}
.portfolio-slider .swiper-slide{
    width: 20rem;
}
.portfolio img{
    width: 24rem;
    border-radius: 19px;
    filter: drop-shadow(-12px 15px 13px rgba(0,0,0,0.25));
}