.experience {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  height: 30vh;
  margin-bottom: 4rem;
  margin-top: -2rem;
}
.achievement{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.circle{
  width: 4rem;
  height: 4rem;
  position: relative;
  font-size: 1.5rem;
  background: white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-bottom: 2rem;
  color: black;
}
.circle::before{
  border-radius: 100%;
  content: ' ';
  top: -8px;
  bottom: -8px;
  right: -8px;
  left: -8px;
  position: absolute;
  z-index: -1;
  background-image: linear-gradient(to bottom, #46adc4 20%, #cf902a 100%);
  box-shadow: var(--boxShadow);
}
.achievement span:nth-of-type(2){
  color: var(--orange);
}
@media  screen and (max-width:480px) {
    .experience{
      display: flex;
      gap: 1rem;
    }
}
