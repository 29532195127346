.card{
    width: 10rem;
    height: 13rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    width: 9rem;
    text-align: center;
    background: transparent;
    border: 7px solid var(--orangeCard);
    border-radius: 25px;
    padding: 0 26px 2rem 26px;
}
.card span:nth-of-type(2){
    color: var(--gray);
    font-size: 16px;
}
.card > img{
    transform: scale(0.6);
    margin-bottom: -2rem;
}
.c-button{
    background: transparent;
    border: none;
    box-shadow: 0 19px 60px rgba(0,0,0,0.08);
    border-radius: 7px;
    padding:10px ;
    font-size: 16px;
    color: rgba(75, 194, 202, 0.493);
}
.c-button:hover{
    cursor: pointer;
    color: beige;
    background: var(--orange);
}
@media  screen and (max-width:480px) {
    .card{
        width: auto;
    }
}
