.works{
    padding: 0 3rem 0 3rem;
    display: flex;height: 90vh;
    margin-top: 4rem;
}
.w-right{
    position: relative;
}
.w-mainCircle{
    position: relative;
    left: 9rem;
    width: 18rem;
    height: 18rem;
    border-radius: 100%;
    box-shadow: var(--smboxShadow);
    top: 2rem;
    background: white;

}
.w-mainCircle>*{
    position: absolute;
}
.w-mainCircle>:nth-child(1){
    top: -3rem;
    left: 6rem;
}
.w-mainCircle>:nth-child(2){
    top: 5rem;
    left: -3rem;
}.w-mainCircle>:nth-child(3){
    top: 5rem;
    left: 6rem;
}.w-mainCircle>:nth-child(4){
    top: 6rem;
    left: 15rem;
}.w-mainCircle>:nth-child(5){
    top: 13rem;
    left: 6rem;
}
.w-secCircle{
    width: 6rem;
    height: 6rem;
    background: whitesmoke;
    border-radius: 100%;
    box-shadow: var(--smboxShadow);
    position: absolute;
    border: 5px solid #ebebeb;
    display: flex;
    align-items: center;
    justify-content: center;
}
.w-secCircle>img{
    transform: scale(.6);
}
.w-backCircle{
    position: absolute;
    height: 13rem;
    width: 13rem;
    border-radius: 100%;
    z-index: -1;
}
.blueCircle{
    background: rgb(7, 94, 151);
    left: 18rem;
    top: 8rem;
}
.yellowCircle{
    background: rgba(255, 255, 0, 0.945);
    left: 18rem;
    top: 0;
}

@media  screen and (max-width:480px) {
    .works{
      display: flex;
      flex-direction: column;
      gap: 5rem;
      height: 56rem;
      padding: 0;
    }
    .w-right{
        align-items: flex-start;
        justify-content: start;
        transform: scale(.7);
        display: flex;

    }
    .w-mainCircle{
        position: static;
    }
    .w-backCircle{
        left: 8rem!important;
    }
}
